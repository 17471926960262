exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-menu-tsx": () => import("./../../../src/pages/menu.tsx" /* webpackChunkName: "component---src-pages-menu-tsx" */),
  "component---src-templates-i-like-template-tsx-content-file-path-src-i-like-libros-el-metodo-bullet-journal-mdx": () => import("./../../../src/templates/ILikeTemplate.tsx?__contentFilePath=/home/runner/work/francho.github.io/francho.github.io/src/i-like/libros/el-metodo-bullet-journal.mdx" /* webpackChunkName: "component---src-templates-i-like-template-tsx-content-file-path-src-i-like-libros-el-metodo-bullet-journal-mdx" */),
  "component---src-templates-i-like-template-tsx-content-file-path-src-i-like-libros-el-peor-viaje-del-mundo-mdx": () => import("./../../../src/templates/ILikeTemplate.tsx?__contentFilePath=/home/runner/work/francho.github.io/francho.github.io/src/i-like/libros/el-peor-viaje-del-mundo.mdx" /* webpackChunkName: "component---src-templates-i-like-template-tsx-content-file-path-src-i-like-libros-el-peor-viaje-del-mundo-mdx" */),
  "component---src-templates-i-like-template-tsx-content-file-path-src-i-like-libros-error-404-mdx": () => import("./../../../src/templates/ILikeTemplate.tsx?__contentFilePath=/home/runner/work/francho.github.io/francho.github.io/src/i-like/libros/error-404.mdx" /* webpackChunkName: "component---src-templates-i-like-template-tsx-content-file-path-src-i-like-libros-error-404-mdx" */),
  "component---src-templates-i-like-template-tsx-content-file-path-src-i-like-libros-klara-y-el-sol-mdx": () => import("./../../../src/templates/ILikeTemplate.tsx?__contentFilePath=/home/runner/work/francho.github.io/francho.github.io/src/i-like/libros/klara-y-el-sol.mdx" /* webpackChunkName: "component---src-templates-i-like-template-tsx-content-file-path-src-i-like-libros-klara-y-el-sol-mdx" */),
  "component---src-templates-i-like-template-tsx-content-file-path-src-i-like-libros-la-asombrosa-tienda-de-la-senora-yeom-mdx": () => import("./../../../src/templates/ILikeTemplate.tsx?__contentFilePath=/home/runner/work/francho.github.io/francho.github.io/src/i-like/libros/la-asombrosa-tienda-de-la-senora-yeom.mdx" /* webpackChunkName: "component---src-templates-i-like-template-tsx-content-file-path-src-i-like-libros-la-asombrosa-tienda-de-la-senora-yeom-mdx" */),
  "component---src-templates-i-like-template-tsx-content-file-path-src-i-like-libros-porque-creemos-en-mierdas-mdx": () => import("./../../../src/templates/ILikeTemplate.tsx?__contentFilePath=/home/runner/work/francho.github.io/francho.github.io/src/i-like/libros/porque-creemos-en-mierdas.mdx" /* webpackChunkName: "component---src-templates-i-like-template-tsx-content-file-path-src-i-like-libros-porque-creemos-en-mierdas-mdx" */),
  "component---src-templates-i-like-template-tsx-content-file-path-src-i-like-libros-proyecto-hail-mary-mdx": () => import("./../../../src/templates/ILikeTemplate.tsx?__contentFilePath=/home/runner/work/francho.github.io/francho.github.io/src/i-like/libros/proyecto-hail-mary.mdx" /* webpackChunkName: "component---src-templates-i-like-template-tsx-content-file-path-src-i-like-libros-proyecto-hail-mary-mdx" */),
  "component---src-templates-i-like-template-tsx-content-file-path-src-i-like-libros-todo-el-azul-del-cielo-mdx": () => import("./../../../src/templates/ILikeTemplate.tsx?__contentFilePath=/home/runner/work/francho.github.io/francho.github.io/src/i-like/libros/todo-el-azul-del-cielo.mdx" /* webpackChunkName: "component---src-templates-i-like-template-tsx-content-file-path-src-i-like-libros-todo-el-azul-del-cielo-mdx" */),
  "component---src-templates-i-like-template-tsx-content-file-path-src-i-like-peliculas-capitan-fantastico-mdx": () => import("./../../../src/templates/ILikeTemplate.tsx?__contentFilePath=/home/runner/work/francho.github.io/francho.github.io/src/i-like/peliculas/capitan-fantastico.mdx" /* webpackChunkName: "component---src-templates-i-like-template-tsx-content-file-path-src-i-like-peliculas-capitan-fantastico-mdx" */),
  "component---src-templates-i-like-template-tsx-content-file-path-src-i-like-peliculas-el-golpe-mdx": () => import("./../../../src/templates/ILikeTemplate.tsx?__contentFilePath=/home/runner/work/francho.github.io/francho.github.io/src/i-like/peliculas/el-golpe.mdx" /* webpackChunkName: "component---src-templates-i-like-template-tsx-content-file-path-src-i-like-peliculas-el-golpe-mdx" */),
  "component---src-templates-i-like-template-tsx-content-file-path-src-i-like-podcasts-catastrofe-ultravioleta-mdx": () => import("./../../../src/templates/ILikeTemplate.tsx?__contentFilePath=/home/runner/work/francho.github.io/francho.github.io/src/i-like/podcasts/catastrofe-ultravioleta.mdx" /* webpackChunkName: "component---src-templates-i-like-template-tsx-content-file-path-src-i-like-podcasts-catastrofe-ultravioleta-mdx" */),
  "component---src-templates-i-like-template-tsx-content-file-path-src-i-like-podcasts-el-descampao-mdx": () => import("./../../../src/templates/ILikeTemplate.tsx?__contentFilePath=/home/runner/work/francho.github.io/francho.github.io/src/i-like/podcasts/el-descampao.mdx" /* webpackChunkName: "component---src-templates-i-like-template-tsx-content-file-path-src-i-like-podcasts-el-descampao-mdx" */),
  "component---src-templates-i-like-template-tsx-content-file-path-src-i-like-podcasts-kaizen-mdx": () => import("./../../../src/templates/ILikeTemplate.tsx?__contentFilePath=/home/runner/work/francho.github.io/francho.github.io/src/i-like/podcasts/kaizen.mdx" /* webpackChunkName: "component---src-templates-i-like-template-tsx-content-file-path-src-i-like-podcasts-kaizen-mdx" */),
  "component---src-templates-i-like-template-tsx-content-file-path-src-i-like-podcasts-nadie-sabe-nada-mdx": () => import("./../../../src/templates/ILikeTemplate.tsx?__contentFilePath=/home/runner/work/francho.github.io/francho.github.io/src/i-like/podcasts/nadie-sabe-nada.mdx" /* webpackChunkName: "component---src-templates-i-like-template-tsx-content-file-path-src-i-like-podcasts-nadie-sabe-nada-mdx" */),
  "component---src-templates-i-like-template-tsx-content-file-path-src-i-like-series-caprica-mdx": () => import("./../../../src/templates/ILikeTemplate.tsx?__contentFilePath=/home/runner/work/francho.github.io/francho.github.io/src/i-like/series/caprica.mdx" /* webpackChunkName: "component---src-templates-i-like-template-tsx-content-file-path-src-i-like-series-caprica-mdx" */),
  "component---src-templates-i-like-template-tsx-content-file-path-src-i-like-series-firefly-mdx": () => import("./../../../src/templates/ILikeTemplate.tsx?__contentFilePath=/home/runner/work/francho.github.io/francho.github.io/src/i-like/series/firefly.mdx" /* webpackChunkName: "component---src-templates-i-like-template-tsx-content-file-path-src-i-like-series-firefly-mdx" */),
  "component---src-templates-i-like-template-tsx-content-file-path-src-i-like-series-for-all-mankind-mdx": () => import("./../../../src/templates/ILikeTemplate.tsx?__contentFilePath=/home/runner/work/francho.github.io/francho.github.io/src/i-like/series/for-all-mankind.mdx" /* webpackChunkName: "component---src-templates-i-like-template-tsx-content-file-path-src-i-like-series-for-all-mankind-mdx" */),
  "component---src-templates-i-like-template-tsx-content-file-path-src-i-like-series-halt-and-catch-fire-mdx": () => import("./../../../src/templates/ILikeTemplate.tsx?__contentFilePath=/home/runner/work/francho.github.io/francho.github.io/src/i-like/series/halt-and-catch-fire.mdx" /* webpackChunkName: "component---src-templates-i-like-template-tsx-content-file-path-src-i-like-series-halt-and-catch-fire-mdx" */),
  "component---src-templates-i-like-template-tsx-content-file-path-src-i-like-series-utopia-mdx": () => import("./../../../src/templates/ILikeTemplate.tsx?__contentFilePath=/home/runner/work/francho.github.io/francho.github.io/src/i-like/series/utopia.mdx" /* webpackChunkName: "component---src-templates-i-like-template-tsx-content-file-path-src-i-like-series-utopia-mdx" */),
  "component---src-templates-page-template-tsx-content-file-path-src-content-ahora-mdx": () => import("./../../../src/templates/PageTemplate.tsx?__contentFilePath=/home/runner/work/francho.github.io/francho.github.io/src/content/ahora.mdx" /* webpackChunkName: "component---src-templates-page-template-tsx-content-file-path-src-content-ahora-mdx" */),
  "component---src-templates-page-template-tsx-content-file-path-src-content-blog-2019-05-01-5-consejos-para-usar-el-movil-mdx": () => import("./../../../src/templates/PageTemplate.tsx?__contentFilePath=/home/runner/work/francho.github.io/francho.github.io/src/content/blog/2019-05-01-5-consejos-para-usar-el-movil.mdx" /* webpackChunkName: "component---src-templates-page-template-tsx-content-file-path-src-content-blog-2019-05-01-5-consejos-para-usar-el-movil-mdx" */),
  "component---src-templates-page-template-tsx-content-file-path-src-content-index-mdx": () => import("./../../../src/templates/PageTemplate.tsx?__contentFilePath=/home/runner/work/francho.github.io/francho.github.io/src/content/index.mdx" /* webpackChunkName: "component---src-templates-page-template-tsx-content-file-path-src-content-index-mdx" */),
  "component---src-templates-page-template-tsx-content-file-path-src-content-me-gusta-mdx": () => import("./../../../src/templates/PageTemplate.tsx?__contentFilePath=/home/runner/work/francho.github.io/francho.github.io/src/content/me-gusta.mdx" /* webpackChunkName: "component---src-templates-page-template-tsx-content-file-path-src-content-me-gusta-mdx" */),
  "component---src-templates-page-template-tsx-content-file-path-src-content-proyectos-aigor-mdx": () => import("./../../../src/templates/PageTemplate.tsx?__contentFilePath=/home/runner/work/francho.github.io/francho.github.io/src/content/proyectos/aigor.mdx" /* webpackChunkName: "component---src-templates-page-template-tsx-content-file-path-src-content-proyectos-aigor-mdx" */),
  "component---src-templates-page-template-tsx-content-file-path-src-content-proyectos-mdx": () => import("./../../../src/templates/PageTemplate.tsx?__contentFilePath=/home/runner/work/francho.github.io/francho.github.io/src/content/proyectos.mdx" /* webpackChunkName: "component---src-templates-page-template-tsx-content-file-path-src-content-proyectos-mdx" */),
  "component---src-templates-page-template-tsx-content-file-path-src-content-uso-mdx": () => import("./../../../src/templates/PageTemplate.tsx?__contentFilePath=/home/runner/work/francho.github.io/francho.github.io/src/content/uso.mdx" /* webpackChunkName: "component---src-templates-page-template-tsx-content-file-path-src-content-uso-mdx" */)
}

